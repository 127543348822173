import { getURL } from "@/store/modules/teams/actions.js";
export default {
  computed: {
    authToken() {
      return `Bearer ${this.$keycloak.token}`;
    }
  },
  methods: {
    getTopicsListURL(courseId) {
      let obj = {
        courseId: courseId
      };
      return getURL(this.$store.getters.allConfig.baseURL, obj).GET_TOPICS_LIST;
    },
    getTeamsListURL(courseId, topicId) {
      let obj = {
        courseId: courseId,
        topicId: topicId
      };
      return getURL(this.$store.getters.allConfig.baseURL, obj).GET_TEAMS_LIST;
    },
    getTeamMembersListURL(courseId, teamId) {
      let obj = {
        courseId: courseId,
        teamId: teamId
      };
      return getURL(this.$store.getters.allConfig.baseURL, obj)
        .GET_TEAM_MEMBERS_DETAIL;
    },
    getTeamDiscussionsListURL(courseId, topicId) {
      let obj = {
        courseId: courseId,
        topicId: topicId
      };
      return getURL(this.$store.getters.allConfig.baseURL, obj)
        .LIST_TEAM_DISCUSSIONS;
    },
    getMyTeamsListURL(courseId) {
      let obj = {
        courseId: courseId
      };
      return getURL(this.$store.getters.allConfig.baseURL, obj)
        .GET_MY_TEAMS_LIST;
    }
  }
};
