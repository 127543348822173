<template>
  <div>
    <TeamsCollapse
      v-for="(d, index) in dataClone"
      :key="d.id"
      :title="d.name"
      :open="index === 0"
      accordion="topics-accordion"
      :teamCount="strTeamCount(d.team_count)"
      :themeColor="getThemeColor(index)"
      @touched="d.isTouched = true"
    >
      <PaginationV2
        v-if="index === 0 || d.isTouched === true"
        :ref="`teamlistwrapper-${d.id}`"
        dataComponentPath="components/Teams/PaginationWrappers/TeamsListWrapper.vue"
        emptyStateComponentPath="components/Teams/PaginationWrappers/TeamsListEmptyWrapper.vue"
        :dataFetchURL="getTeamsListURL(courseId, d.id)"
        :authToken="authToken"
        :pageSizeValue="3"
        :additionalProps="additionalProps(d, index)"
        :disablePagination="true"
        @createNewTeam="createTeam(d)"
        @hasData="d.isViewAll = true"
      ></PaginationV2>
      <div class="d-flex justify-content-center" v-if="d.isViewAll">
        <Button
          variant="link"
          className="text-uppercase"
          @click="viewAll(d, index)"
          >{{ $t("general.view_all") }}</Button
        >
      </div>
    </TeamsCollapse>
    <create-team-modal :topicId="topicId" @success="onTeamCreated" />
  </div>
</template>
<script>
import TeamsPaginationMixin from "@/components/Teams/PaginationWrappers/mixin.js";
import CreateTeamModal from "../Shared/CreateTeam.vue";

export default {
  mixins: [TeamsPaginationMixin],
  props: ["data"],
  components: { CreateTeamModal },
  data() {
    return {
      themeColors: ["#D3CBFF", "#B1E8BF", "#FEC2CD", "#C4E7EE", "#FFE597"],
      dataClone: [],
      viewAllTeamsBtn: false,
      topicId: ""
    };
  },
  watch: {
    data() {
      this.init();
    }
  },
  computed: {
    courseId() {
      return this.$route.params.id;
    }
  },
  methods: {
    init() {
      this.dataClone = this.data.map((obj, i) => ({
        ...obj,
        isTouched:
          this.dataClone[i] && this.dataClone[i].isTouched
            ? this.dataClone[i].isTouched
            : false,
        isViewAll:
          this.dataClone[i] && this.dataClone[i].isViewAll
            ? this.dataClone[i].isViewAll
            : false
      }));
    },
    strTeamCount(teamCount) {
      return teamCount > 1 ? `${teamCount} teams` : `${teamCount} team`;
    },
    additionalProps(topic, index) {
      return {
        themeColor: this.getThemeColor(index),
        topicName: topic.name,
        topicId: topic.id
      };
    },
    viewAll(topic, index) {
      this.$router.push({
        name: "TeamList",
        params: {
          topicId: topic.id
        },
        query: {
          topicName: topic.name,
          themeColor: this.getThemeColor(index)
        }
      });
    },
    getThemeColor(i) {
      return this.themeColors[i % this.themeColors.length];
    },
    createTeam(d) {
      this.$bvModal.show("create-team-modal");
      this.topicId = d.id;
    },
    onTeamCreated() {
      this.$refs[`teamlistwrapper-${this.topicId}`][0].reload();
    }
  }
};
</script>
